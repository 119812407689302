// Simplified Artez footer

.pageFooter { 
  display: block;
  padding: 2em 0 3em;
  ul.socialIcons{
    margin-bottom:20px;
    display: none;
  }
  .Footercredits{
    text-align: center;
  }
  ul.footerLinks li{
    display: inline-block;
    margin: 0 20px 20px 0;
  }
}

// Donation thank you page - hide 'back to home page' button

.donationThankYouWrapper .donationShare a.cta-button {
  display: none;
}

// Donation forms - hide 'Display your name with your donation on our site'

.formQuestion.displayName.labelCheckBoxPair {
  display: none;
}

// https://raisingit.atlassian.net/browse/DEL-141
.menuAdminLogin,
.menuAdminBasket {
  display: none !important;
}